import axios from './common/apiConfig';
import urlConfig from '@/config/urlConfig';

//store-common 에 연결됨.
async function sendFCMToken(params) {
  return await axios.postQs(`${urlConfig.FIT_BASE_URL}/fit/setFCMToken.tvp`, { ...params, loginCheck: 'false' });
}

async function getHomePointData(params) {
  return await axios.postQs(`${urlConfig.FIT_BASE_URL}/point/getHomePointData.tvp`, params);
}

async function fetchSubscribeList() {
  return await axios.get(`${urlConfig.PAI_BASE_URL}subscribe/fcm_topics`);
}

async function fcmSubscribe(params) {
  // return await axios.post(`${urlConfig.FCM_BASE_URL}v1:batchAdd`, params);
  return await axios.post(`${urlConfig.PAI_BASE_URL}guest/subs`, params);
}

async function fcmUnSubscribe(params) {
  // return await axios.post(`${urlConfig.FCM_BASE_URL}v1:batchRemove`, params);
  return await axios.post(`${urlConfig.PAI_BASE_URL}guest/unsubs`, params);
}

async function fcmSubscribeList(params) {
  return await axios.post(
    `${urlConfig.FCM_BASE_URL}info/dKqJt9T6TUGPOMoMzrJ0Ux:APA91bGSCiMArgKewTxa2oWchbFheTbIAd6WfTip-ZaSU2c7l_IFcVpsoEURc8SArTH29LRsBrtfukJxU14TQswwGKjEtwp1uZ_xMKjiTqa5vfDCWx5qZ1ZdoBB97RTS4n01n0KheOLR?details=true`,
  );
}

export { sendFCMToken, getHomePointData, fetchSubscribeList, fcmSubscribe, fcmUnSubscribe, fcmSubscribeList };
